
import React, {useState} from "react"
import Layout from "../../layout"

import WebappsForm from "../form"

const webappsAPI = require("../../../../lib/requestWebapps");

const ENTITYID="panelaccess"


const panelgrouppickerfields = [
	{"label":"Name", "dbfield": "panelgroup_name", "type": "text", "filtertype": "textbox"},
];

const panelgrouppickerfilter = [
	//{"field":"onsorganization_id", "operation": ">", "value": 0},
];

const usertypepickerfields = [
	{"label":"Name", "dbfield": "usertype_name", "type": "text", "filtertype": "textbox"},
	{"label":"Description", "dbfield": "usertype_description", "type": "text", "filtertype": "textbox"},
];

const usertypepickerfilter = [
	{"field":"usertype_id", "operation": ">", "value": 3},
];



/*

	function menureset_deleteaccess($groupid, $usertypeid)
	{
		db_query("delete from PANELACCESS where panelgroup_id=$groupid and usertype_id=$usertypeid");
	}
	function permission_getsetlist()
	{
		return db_query("select panelset_id,panelset_name from PANELSET where panelset_id<>".PANELSET_HIDDEN);
	}

	function permission_getuserpermission($usertype, $groupid)
	{
		return db_getqueryid("select panelaccess_accessallow from PANELACCESS where usertype_id=$usertype and panelgroup_id=$groupid");
	}

	function permission_updateuserpermission($groupid, $usertypeid, $accessallow)
	{
		// assumes unique index
		db_query("insert into PANELACCESS (panelgroup_id, usertype_id,panelaccess_accessallow) VALUES ($groupid,$usertypeid, $accessallow)");
		db_query("update PANELACCESS set panelaccess_accessallow=$accessallow  where panelgroup_id=$groupid and usertype_id=$usertypeid");
	}


*/
const searchFields = [
	{"label":"User Type", "dbfield": "usertype_name", "type": "text", "filtertype": "text"},
	{"label":"Table/Entity", "dbfield": "panelgroup_name", "type": "text", "filtertype": "text"},
	{"label":"Access Type", "dbfield": "panelaccess_accessallow", "type": "custom", "filtertype": "integer"}
];


const formFields = [
	[
		{"label":"User Type", "field": "usertype_name", "value": "", "input": "picker", "mode": "unique", "pickerfields": usertypepickerfields, "pickerfilters": usertypepickerfilter},
		{"label":"Table/Entity", "field": "panelgroup_name", "value": "", "input": "picker", "mode": "unique", "pickerfields": panelgrouppickerfields, "pickerfilters": panelgrouppickerfilter},
		{"label":"Access Type", "field": "panelaccess_accessallow", "value": "", "input": "combo", "mode": "required", "options": [
			{"value":2,"display":"View"},
			{"value":8,"display":"Add/Edit"},
			{"value":128,"display":"Full"},
		], "info":"Delete to remove permission"},

	]
];

const UserpermissionsForm = ({location, allowadd, allowdelete}) => {
	const [token, setToken] = useState("");
	const [pagetitle, setPagetitle] = useState("");


	function customSubmit(entity, params, payloadlist, subformpayloadlist, token, callback)
	{
		/*
		var pkid = 0;
		if (params.hasOwnProperty("documentid")) {
			if (params.documentid) {
				pkid = params.documentid;
			}
		}
		*/

		//callback({"status":"Error", "message":"Debug"}); return;

		// TODO: Update final JSON file
		webappsAPI.savePayload(entity, params, token, payloadlist, subformpayloadlist, function(response){
			callback(response);
		});

	}


	function formValidateValues(mainform, subformlist, subformfooterlist)
	{
		/*
		var idx = 0;
		while (idx < mainform.length) {
			if (mainform[idx].field === "apijwtuserjsonmenu_data") {
				try {
					JSON.parse(mainform[idx].value);
					// todo additional checking
				} catch(checkjsonerr) {
					return {"status":"Error", "message":"Error"+checkjsonerr.message.replace("JSON.parse","")};

				}
			}
			idx++;
		}
		*/
		return {"status":"OK"};
	}

	function customSearchField(field, idx, rowdata, styles)
	{
		if (field.dbfield === "panelaccess_accessallow") {
			const curval = parseInt(rowdata[field.dbfield], 10);
			var tmpout = curval+" (";
			if (curval === 2) {
				tmpout=tmpout+"View";
			} else if (curval === 8) {
				tmpout=tmpout+"Add/Edit";
			} else if (curval === 128) {
				tmpout=tmpout+"Full";
			}
			return <>{tmpout+")"}</>
		}
		return <></>
	}
	return <Layout
			fullPath={location.pathname}
			newPageTitle={(newtitle)=>{setPagetitle(newtitle)}}
			newtokenHandler={(newtoken)=>{setToken(newtoken)}}
			privatePage={true} usegatedcontent={true}>
			<WebappsForm
				location={location}
				allowadd={true}
				allowdelete={true}
				pagetitle={pagetitle}
				mobilerowtitlefield={["usertype_name", "panelgroup_name"]}
				searchFields={searchFields}
				editFields={formFields}
				userSearchFilter={[{"field":"usertype_id", "operation":">", "value": 3}]}
				//customSubmit={customSubmit}
				customValidateValues={formValidateValues}
				customSearchField={customSearchField}
				entity={ENTITYID}
				token={token} />
		</Layout>
}


export default UserpermissionsForm;
