
import React from "react"

import UserpermissionsForm from "../../components/webapps/forms/userpermissions";

const UserpermissionsPage = ({location}) => {

	return <UserpermissionsForm
				location={location}
			/>
}

export default UserpermissionsPage;
